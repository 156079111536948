
import { Container } from "react-bootstrap";
import { NoTransitionExample } from "../componentes/Carrossel";
import { ListaProdutos } from "../componentes/ListaProdutos";
import { OffcanvasExample } from "../componentes/Navbar";




function Home(){


    return(
        <>
        <OffcanvasExample/>
        <Container>
        <NoTransitionExample/>
        
        <ListaProdutos/>
        </Container>
        </>
    );
}

export {Home}