import Carousel from 'react-bootstrap/Carousel';

import img1 from "../assets/images/pexels-nandhukumar-1011630.jpg"
import img2 from "../assets/images/pexels-pixabay-326929.jpg"
import img3 from "../assets/images/pexels-pixabay-86594.jpg"


function NoTransitionExample() {
  return (
    <>
    <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',
        marginTop: '10vh'
        }} >
    <Carousel data-bs-theme="dark" style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',
        maxWidth: '50vw',
    }}>
      <Carousel.Item>
        <img style={{
            maxHeight : '50vh',
            minHeight : '2vh'
        }}
          className="d-block w-100 h-50"
          src={img1}
          alt="First slide"
          
        />
        <Carousel.Caption>
          <h5>First slide label</h5>
          <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img style={{
            maxHeight : '50vh',
            minHeight : '2vh'
        }}
          className="d-block w-100 h-50"
          src={img2}
          alt="Second slide"
        />
        <Carousel.Caption>
          <h5>Second slide label</h5>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img style={{
            maxHeight : '50vh',
            minHeight : '2vh'
        }}
          className="d-block w-100 h-50"
          src={img3}
          alt="Third slide"
        />
        <Carousel.Caption>
          <h5>Third slide label</h5>
          <p>
            Praesent commodo cursus magna, vel scelerisque nisl consectetur.
          </p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
    </div>
    </>
  );
}

export  {NoTransitionExample};